<template>
  <div id="loader" :style="height ? `height: ${height}px` : 'height:100%'">
    <v-progress-circular
      indeterminate
      :size="size"
      :color="color"
      :width="width"
    />
  </div>
</template>

<script>
export default {
  props: {
    height: [String, Number],
    color: {
      type: String,
      default: "primary"
    },
    width: {
      type: [String, Number],
      default: 2
    },
    size: [String, Number]
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
#loader {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  overflow: visible !important;
}
</style>
